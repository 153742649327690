import React from 'react';
import unlimited from "../assets/unlimited.png"
import { jioplans } from '../Plans/plans';

const RechargeCard = ({mobileOperator}) => {
  const handleBuyClick = (price) => {
    if (!window.PaymentRequest) {
      return;
    }

    const supportedInstruments = [
      {
        supportedMethods: ["https://tez.google.com/pay"],
        data: {
          pa: "ycfltmm72k63@idbi",
          pn: mobileOperator,
          tr: "1234ABCD",
          url: "https://yourwebsite.com/order/1234ABCD",
          mc: "1234",
          tn: `Paying to ${mobileOperator}`,
        },
      },
    ];

    const details = {
      total: {
        label: "Total",
        amount: {
          currency: "INR",
          value: price,
        },
      },
      displayItems: [
        {
          label: "Original Amount",
          amount: {
            currency: "INR",
            value: price,
          },
        },
      ],
    };

    const request = new PaymentRequest(supportedInstruments, details);
    request.show();
  };
  return (
    jioplans.map((el)=>(
      <div style={{
        boxShadow: "rgba(59, 130, 246, 0.24) 0px 3px 8px",
        display:"flex",
        flexDirection:"column",
        justifyContent:"flex-start",
        padding:"20px",
        gap:"10px",
        borderRadius:"10px"
  
      }}>
        <button style={{
          backgroundColor:"#d62151",
          color:"white",
          border:"none",
          fontWeight:500,
          fontSize:"12px",
          padding:"5px 10px",
          borderRadius:"5px",
          width:"80px"
        }}>
          Exclusive
        </button>
        <div style={{
          display:"flex",
          justifyContent:"space-between",
          alignItems:"center",
        }}>
          <div style={{display:"flex",gap:"8px"}}>
            <span style={{fontSize:"16px", fontWeight:"bold"}}>₹{el.price}</span>
            <span style={{fontSize:"16px",color:"grey",textDecoration:"line-through",fontWeight:"bold"}}>₹{el.mrp}</span>
          </div>
          <div>
            <img src={unlimited} alt="undj"  style={{width:"70px"}}/>
          </div>
        </div>
        <div style={{display:"flex",justifyContent:"space-between",textAlign:"left"}}>
          <div style={{
            display:"flex",
            flexDirection:"column"
          }}>
            <span style={{color:"rgb(71 85 105 / 1)", fontSize:"12px"}}>VALIDITY</span>
            <span style={{fontWeight:500,fontSize:"14px"}}>{el.validity}</span>
          </div>
          <div style={{
            display:"flex",
            flexDirection:"column"
          }}>
            <span style={{color:"rgb(71 85 105 / 1)",fontSize:"12px"}}>DATA</span>
            <span style={{fontWeight:500,fontSize:"14px"}}>{el.data}</span>
          </div>
          <div style={{
            display:"flex",
            flexDirection:"column"
          }}>
            <span style={{color:"rgb(71 85 105 / 1)",fontSize:"12px"}}>VOICE</span>
            <span style={{fontWeight:500,fontSize:"14px"}}>{el.calls}</span>
          </div>
          <div style={{
            display:"flex",
            flexDirection:"column"
          }}>
            <span style={{color:"rgb(71 85 105 / 1)",fontSize:"12px"}}>SMS</span>
            <span style={{fontWeight:500,fontSize:"14px"}}>100/day</span>
          </div>
         
        </div>
  
        <button
              onClick={()=>handleBuyClick(el.price)}
              style={{
                color: "white",
                fontWeight: "bold",
                border: "none",
                background: "#3b82f6",
                padding: "10px",
                width: "100%",
                borderRadius:"30px",
                fontSize:"15px",
                marginTop:"15px"
              }}
            >
              Recharge
            </button>
      </div>
    ))
   
  );
};

export default RechargeCard;
